.Home-course-Card {
  background-color: #f9f9f979;
}
.container-heading {
  font-weight: 700;
  font-size: 40px;
  font-family: dosis;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 100px;
    @media (width < 546px){
    margin-bottom: 30px;
    font-size: 35px;
  }
}

.container-heading::after {
  content: "";
  display: inline-block;
  width: 10%;
  height: 3px;
  background-color: var(--highlight-color);
  position: relative;
  left: 10px;
  bottom: 7px;
}

.container-heading::before {
  content: "";
  display: inline-block;
  width: 10%;
  height: 3px;
  background-color: var(--highlight-color);
  position: relative;
  right: 10px;
  bottom: 7px;
}

.course {
  display: flex;
  flex-direction: column;
  /* background-color: var(--highlight-color); */
  transition: 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  height: 450px;
  color: var(--text-color);
  border-radius: 10px;
  width: 90%;
  color: var(--text-color);
}
.course-img {
  width: 100%;
  height: 250px;
  overflow: hidden;
}
.course-img img {
  width: 100%;
  height: auto;
}
.course-content {
  padding: 10px;
}
.course-content h3{
  font-size: 28px;
  font-weight: 600;
  font-family: dosis;
  color: var(--background-color);
  margin-bottom: 18px;
}
.course-content p{
  font-size: 18px;
  font-weight: 500;
  font-family: dosis;
  color: var(--background-color);
}
.rating {
  color: var(--highlight-color);
  font-size: 18px;
}
.rate p span {
  color: var(--highlight-color);
  font-size: 18px;
  font-weight: 600;
}
.rate p{
  font-weight: 600;
}
.price span{
  font-size: 30px;
  font-family: dosis;
  font-weight: 700;
  color: var(--background-color);
}
.course-content button {
  background-color: transparent;
  color: var(--link-color);
  border: 1px solid var(--highlight-color);
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  width: 100%;
}
.course-content button:hover{
  background-color: var(--highlight-color);
  color: var(--text-color);
}
.certificate {
  display: flex;
  flex-direction: column;
  background-color: var(--highlight-color);
  padding: 20px;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  height: 344px;
  color: var(--text-color);
  justify-content: center;
  align-items: center;
  @media(min-width: 992px) {
    background-color: var(--highlight-color);
  }
}
.certificate2 {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color);
  padding: 20px;
  overflow: hidden;
  height: 344px;
  color: var(--text-color);
  justify-content: center;
  align-items: center;

}
.certificate2 .certified-text {
  color: var(--text-color);
}
.certificate2 .certified-text p{
  color: var(--text-color);
}
.certificate .certified-text {
  color: var(--text-color);
}
.certificate .certified-text p{
  color: var(--text-color);
}
.certificate2 .certified-card-icon svg{
  color: var(--text-color);
}
.certificate .certified-card-icon svg{
  color: var(--text-color);
}

.certified-card-icon svg {
  font-size: 80px;
  /* color: var(--primary-color); */
}
.certified-text h4 {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 10px;
  /* color: var(--text-color); */
  font-family: dosis;
  text-align: center;
    @media (width < 768px) {
    font-size: 25px;
  }
}
.certified-text p {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
  /* color: var(--text-color); */
  font-family: dosis;
  text-align: center;
  @media (width < 768px) {
    font-size: 18px;
  }
}