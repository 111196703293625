@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

.home {
  background-image: linear-gradient(5deg, #2828acc0 1%, #14352598 100%);
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero {
  height: 90vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-text {
  color: white;
  text-align: center;
  padding: 20px;
}

.home-text h1 {
  font-size: 3.2em;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--highlight-color);
}

.home-text p {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 20px;
  max-width: 80%;
  margin-inline: auto;
}

@media screen and (max-width: 570px) {
  .home-text p {
    font-size: 13px;
  }
}

* {
  padding: 0px;
  margin: 0px;
}
.main-container {
  margin: 0px;
  padding: 0px;
  background-color: black;
  min-height: 100vh;
  position: relative;
  margin-top: -90px;
  padding-top: 60px;
}
.blur-circle1 {
  width: 200px;
  height: 200px;
  background: linear-gradient(to bottom, white, pink);
  border-radius: 50%;
  filter: blur(120px);
  position: absolute;
  left: 8%;
  top: 50%;
  /* transform: translate(50%); */
}
.blur-circle2 {
  width: 200px;
  height: 200px;
  background: linear-gradient(to bottom, white, pink);
  border-radius: 50%;
  filter: blur(100px);
  position: absolute;
  right: 10%;
  top: 20%;
  /* transform: translate(50%); */
}
body {
  font-family: "Open Sans", sans-serif;
}
.containered {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
/* Small */
@media (min-width: 768px) {
  .containered {
    width: 750px;
  }
  .landing-page .content .info h1 {
    font-size: 2em;
  }
}
/* Medium */
@media (min-width: 992px) {
  .containered {
    width: 980px;
  }
  .landing-page .content .info h1 {
    font-size: 2em;
  }
}
/* Large */
@media (min-width: 1200px) {
  .containered {
    width: 1170px;
  }
}
/* End Global Rules */

.landing-page .content .containered {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 140px;
  min-height: calc(100vh - 80px);
}
@media (max-width: 767px) {
  .landing-page .content .containered {
    gap: 0;
    min-height: calc(100vh - 101px);
    justify-content: center;
    flex-direction: column;
  }
  .main-image {
    /* mix-blend-mode:exclusion */
    width: 600px;
    height: 400px;
    /* visibility: hidden;
   */
    /* display: none; */
  }
  .landing-page .content .info {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 15px;
  }
  .landing-page .content .info h1 {
    color: #bbbbbb;
    font-size: 4em;
    font-weight: 600;
  }
}

.landing-page .content .info h1 {
  color: #bbbbbb;
  font-size: 4em;
  font-weight: 600;
}
.landing-page .content .info p {
  margin: 0;
  line-height: 1.6;
  font-size: 1.4em;
  color: #b3b3b3;
}
.landing-page .content .info button {
  border: 0;
  border-radius: 20px;
  padding: 12px 30px;
  margin-top: 30px;
  cursor: pointer;
  color: var(--text-color);
  background-color: #6c63ff;
}
.landing-page .content .image img {
  max-width: 100%;
}
/* End Landing Page */
.main-image {
  /* mix-blend-mode:exclusion */
  width: 600px;
  height: 400px;
}
h1,
p {
  color: var(--text-color);
}
/* ==============================================OFFER===================================================== */
.offer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 40px;
  @media (width < 546px) {
    padding-inline: 10px;
  }
}

.offer h3 {
  font-size: 33px;
  font-weight: 600;
  color: var(--background-color);
  font-family: dosis;
  /* text-align: center; */
}
.offer p {
  font-size: 21px;
  font-weight: 500;
  color: var(--background-color);
  font-family: "Figtree", sans-serif;
  /* max-width: 90%; */
  opacity: 0.8;
  text-align: justify;
  @media (width < 546px) {
    font-size: 18px;
  }
}
.offer-icon-section {
  display: flex;
  gap: 20px;
  /* width: 50%; */
  margin-bottom: 20px;
}
.offer-icon-section .offer-icon {
  border-radius: 50%;
  background-color: var(--highlight-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-left: 0;
}

.offer-icon-section .offer-icon svg {
  color: var(--text-color);
  font-size: 50px;
}
.offer-icon-section .offer-icon-desc {
  align-content: center;
  width: 60%;
}
.offer-icon-section .offer-icon-desc p {
  font-family: dosis;
  font-size: 19px;
  /* opacity: 0.7; */
  text-align: left;
  letter-spacing: 0.8px;
  color: rgba(0, 0, 0, 0.766);
  line-height: 1.2;
  font-family: "Figtree", sans-serif;
}

.offer-icon-section .offer-icon-desc h2 {
  font-family: dosis;
  font-size: 28px;
  font-weight: 600;
  opacity: 0.8;
  color: var(--link-color);
  line-height: 1.2;
  letter-spacing: 0.6px;
  /* margin-top: 18px; */
}
.offer-side {
  background-color: #f9f9f988;
}
.about-info h4{
  font-family: dosis;
  font-size: 28px;
  font-weight: 600;
  color: var(--background-color);
}
.about-info p {
  font-family: "Figtree", sans-serif;
  font-size: 18px;
  color: var(--background-color);
  line-height: 1.5;
  opacity: 0.8;
}

/* ======================================vidSection=================================== */
.vid-container {
  background-image: url("../../Assets/ymastudent.jpeg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.vid-content {
  background-image: linear-gradient(to left, #3b3bf2c8 10%, #14352598 100%);
  width: 100%;
  height: 100vh; /* Changed to match container height */
  position: absolute;
  top: 0; /* Ensure it starts from the top */
  left: 0; /* Ensure it starts from the left */
}

.vid-content-program {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
  width: 100%;
  margin-top: 1%;
}

.vid {
  max-width: 750px;
  border-radius: 10px;
  overflow: hidden;
}

.vid video {
  width: 100%;
  height: auto;
}

.vid-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vid-text h2 {
  font-size: 3em;
  font-weight: 600;
  margin: 0;
  color: var(--highlight-color);
}

.vid-text p {
  color: var(--text-color);
  font-size: 25px;
  max-width: 400px;
}

.counter-section {
  padding: 20px;
  gap: 20px;
  width: 80%;
  /* display: grid;
  justify-content: center; */
  margin-left: 10%;
  margin-right: 10%;
}

.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  /* background: rgba(255, 255, 255, 0.8);  */
  border-radius: 10px;
  flex: 1;
  margin: 0 10px;
  width: calc((100% - 20px) / 3);
}

.counter-number {
  font-size: 4em;
  font-weight: bold;
  color: var(--text-color);
}

.counter-title {
  font-size: 1.5em;
  margin-top: 10px;
  color: var(--text-color);
}

/* ========================================responsive=============================== */
@media (width < 992px) {
  .landing-page .content .containered {
    gap: 40px;
    min-height: calc(100vh - 80px);
  }
  .containered {
    max-width: 1000px !important;
  }
  .landing-page .content .info h1 {
    font-size: 3em !important;
  }
}
@media (width < 1180px) {
  .landing-page .content .info h1 {
    font-size: 3.5em;
  }
  .containered {
    max-width: 1180px;
  }
  .landing-page .content .containered {
    gap: 40px;
    min-height: calc(100vh - 80px);
  }
  .offer p {
    font-size: 20px;
  }
}
@media (width < 768px) {
}
@media (width < 542px) {
  .course {
    width: 100%;
  }
  .offer-icon-section .offer-icon-desc h2 {
    font-size: 23px;
  }
  .offer-icon-section .offer-icon-desc p {
    font-size: 16px;
    text-align: left;
  }
  .offer-icon-section .offer-icon {
    height: 70px;
    width: 70px;
  }
  .offer-icon-section .offer-icon svg {
    font-size: 30px;
  }
  .course {
    width: 100% !important;
  }
  .vid-content-program {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    width: 100%;
  }
  .vid-text h2 {
    font-size: 2em;
    font-weight: 600;
    margin: 0;
    color: var(--highlight-color);
  }

  .vid-text p {
    color: var(--text-color);
    font-size: 17px;
    max-width: 400px;
  }
  .counter-section {
    padding: 0px;
    gap: 20px;
    width: 96%;
    /* display: grid;
    justify-content: center; */
    margin-left: 2%;
    margin-right: 2%;
  }
  .vid video {
    height: 200px !important;
    width: 450px !important;
  }
  .vid {
    max-width: 450px;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
  }
  .counter-number {
    font-size: 2em;
    font-weight: bold;
    color: var(--text-color);
  }

  .counter-title {
    font-size: 0.8em;
    margin-top: 10px;
    color: var(--text-color);
  }
}
